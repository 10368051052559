import React from "react"
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import { Box, Flex, Image } from "@rebass/emotion"

import {
  Layout,
  Container,
  SEO,
  Text,
  Section,
  Heading,
  DecoratedHeading,
} from "../components"
import Map from "../components/Map"
import LogoImage from "../images/home/logo.png"

export const pageQuery = graphql`
  query HomeQuery {
    partners: allFile(filter: { relativePath: { regex: "/home/partners/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 197) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    prismicHome {
      data {
        initiative_image_1 {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 570) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        initiative_image_2 {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 570) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        whos_responsible_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 340) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default function IndexPage({ data }) {
  const vidEl = React.useRef(null)

  console.log(data)

  React.useEffect(_ => {
    vidEl.current.play()
  })
  return (
    <Layout>
      <link
        href="https://api.tiles.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.css"
        rel="stylesheet"
      />
      <SEO title="Home" keywords={[``]} />
      <Section>
        <Flex
          bg="#222"
          color="white"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          px="2"
          css={theme => css`
            height: calc(100vh - ${theme.heights.nav});
            position: relative;
            width: 100%;
            overflow: hidden;
          `}
        >
          <video
            src="https://vrettamedia.s3.amazonaws.com/gatsby-ELI/videos/ELI_Background_Clips_original_mnbmwk.mp4"
            ref={vidEl}
            loop
            autoPlay
            muted
            css={css`
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 0.3;
              object-fit: cover;
              width: 100%;
              height: 100%;
              pointer-events: none;
            `}
          />
          <Image src={LogoImage} alt="" mb={3} width={["60%", "60%", "auto"]} />
          <Heading h3>{`Educator & Leadership Institute`}</Heading>
        </Flex>
      </Section>
      <Section>
        <Container>
          <DecoratedHeading type="cornerBlack">
            {`The ELI Initiative`}
          </DecoratedHeading>
          <Text>
            {`Education provides the tools for young people to access diversified career options, higher income levels, sustained health and active citizenship. To achieve these outcomes, children must receive a quality education taught by skilled and competent teachers and school leaders who have the capacity to foster these abilities.`}
          </Text>
        </Container>
        <Flex
          width="90%"
          mx="auto"
          mb="5"
          flexWrap="wrap"
          justifyContent="center;"
          css={css`
            & > div {
              max-width: 570px;
            }
          `}
        >
          <Box width={[1, 1, 1, 1, 1 / 3]}>
            <Box
              css={theme => css`
                position: relative;
                ${theme.mq[3]} {
                  top: 80px;
                }
              `}
            >
              <GatsbyImage
                fluid={
                  data.prismicHome.data.initiative_image_1.localFile
                    .childImageSharp.fluid
                }
              />
            </Box>
          </Box>
          <Box
            bg="black"
            color="white"
            px="4"
            py="5"
            css={theme => css`
              ${theme.mq[3]} {
                border-radius: ${theme.borderRadius[1]};
              }
            `}
            width={[1, 1, 1, 1, 1 / 3]}
          >
            <Text>
              The Educator and Leadership Institute (ELI) builds teaching and
              leadership capacity in communities globally through professional
              courses and workshops. The first ELI was launched in northern
              Haiti in August 2016 and continues to support educators in
              science, mathematics, critical literacy, early learning, special
              education and school leadership. The initial objective of ELI
              Haiti was to impact the learning outcomes of 100,000 students by
              directly instructing 1,000 teachers and 100 principals in Haiti by
              2020. We are well on our way to accomplishing this goal.
            </Text>
            <Text>
              The vision for ELI is founded on a Haitian Creole expression,"Piti
              piti, ti pay pay, zwazo fe nich – little by little, straw by
              straw, the bird builds its nest." Since the start of ELI Haiti,
              other ELI sites have been established in Nepal and Egypt. Future
              ELI sites are being developed in Ethiopia, Kenya, and Ghana.
            </Text>
          </Box>
          <Box width={[1, 1, 1, 1, 1 / 3]} alignSelf="flex-end" mb="6">
            <Box
              css={theme => css`
                ${theme.mq[3]} {
                  max-width: ${570 * 0.75}px;
                }
              `}
            >
              <GatsbyImage
                fluid={
                  data.prismicHome.data.initiative_image_2.localFile
                    .childImageSharp.fluid
                }
              />
            </Box>
          </Box>
        </Flex>
        <Container
          p="0"
          css={css`
            height: 500px;
          `}
        >
          <Map />
        </Container>
      </Section>
      <Container>
        <Section pb={75}>
          <DecoratedHeading type="cornerBlack">How It Works</DecoratedHeading>
          <Text>
            Leading educators from Canada as well as university students and
            faculty members, travel to Haiti, Nepal, or Egypt to provide courses
            and engage educators in those contexts in professional learning. The
            courses have a focus on teaching and leadership competencies. The
            course instructors model experiential and problem-based teaching
            methods.
          </Text>
          <Text>
            Each participant commits to a three-year involvement and takes one
            module of the same subject each year (with different materials and
            focus areas) to build deep capacity in that subject area.
          </Text>
          <Text>
            Our in-country partners provide logistical support and arrange
            registration, classroom spaces, transportation, and all other
            Institute details. This supports the hosting of the ELI as a “centre
            of excellence”.
          </Text>
        </Section>

        <Box mb={75} bg="black" pb="2px" />

        <Section>
          <Box
            width={[1, 1, 1, 1 / 3]}
            pr={[0, 0, 0, 4]}
            mb="4"
            css={css`
              float: left;
            `}
          >
            <GatsbyImage
              fluid={
                data.prismicHome.data.whos_responsible_image.localFile
                  .childImageSharp.fluid
              }
            />
          </Box>
          <DecoratedHeading
            h3
            type="left"
            decoratorAlignment="left"
            pt={[0, 0, 0, 5]}
          >
            Who's Responsible?
          </DecoratedHeading>
          <Text>
            Every person who is part of ELI is a volunteer. We all have day jobs
            but are passionate about making a difference in the world.
          </Text>
          <Text>
            Overall leadership is provided by Steve Sider (Wilfrid Laurier
            University) and Jhonel Morvan (Ontario Ministry of Education)
          </Text>
        </Section>

        <Section>
          <Flex justifyContent="center">
            <DecoratedHeading h3>Partners</DecoratedHeading>
          </Flex>
          <Flex
            mb="3"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
          >
            {data.partners.edges.map((node, i) => (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="200px"
                // px="3"
                mb="3"
                key={i}
              >
                <GatsbyImage fixed={node.node.childImageSharp.fixed} key={i} />
              </Flex>
            ))}
          </Flex>
          <Text>
            Funding also from private donors, Rotary Club, Gay Lea Foundation, Charis Foundation
          </Text>
        </Section>
      </Container>
    </Layout>
  )
}
