import React from "react"
import { css } from "@emotion/core"
import MapGL, { Marker, FlyToInterpolator } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { Text } from "../components"
import { Box, Flex } from "@rebass/emotion"
import { navigate } from "gatsby"

const MAPBOX_TOKEN =
  "pk.eyJ1IjoidnJldHRhIiwiYSI6ImNqd2liNGp4czJtYXozeWtkd2Z5M3kxM3QifQ.K212EptAa9LO8Bpybg35Ww" // Set your mapbox token here

const bartStations = [
  { name: "Nepal", coordinates: [84.124, 28.3949], to: "/initiatives/nepal" },
  { name: "Egypt", coordinates: [30.8025, 26.8206], to: "/initiatives/egypt" },
  { name: "Haiti", coordinates: [-72.2852, 18.9712], to: "/initiatives/haiti" },
]

export default class Map extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    clearTimeout(this.timeout)
    const { isScrolling } = this.state

    if (!isScrolling) {
      this.setState({ isScrolling: true })
    }

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false })
    }, 1000)
  }

  defaultViewport = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: 0,
    pitch: 0,
  }
  state = {
    viewport: this.defaultViewport,
    interactionState: {},
    settings: {
      dragPan: true,
      dragRotate: true,
      scrollZoom: false,
      touchZoom: false,
      touchRotate: true,
      keyboard: true,
      doubleClickZoom: false,
      minZoom: 0,
      maxZoom: 20,
      minPitch: 0,
      maxPitch: 0,
    },
    zoomed: false,
    scrolling: false,
  }

  _onViewportChange = viewport => this.setState({ viewport })

  _onInteractionStateChange = interactionState =>
    this.setState({ interactionState })

  _onSettingChange = (name, value) =>
    this.setState({
      settings: { ...this.state.settings, [name]: value },
    })

  _renderMarker = (station, i) => {
    const { name, coordinates } = station
    return (
      <Marker
        key={i}
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        captureDrag={false}
        captureDoubleClick={false}
      >
        <div
          className="station"
          onClick={() => this._goToCoords(station)}
          css={css`
            border-radius: 20px;
            padding-right: 12px;
            margin: -12px;
            color: transparent;
            line-height: 24px;
            font-size: 13px;
            white-space: nowrap;
            cursor: pointer;
            pointer-events: ${this.state.zoomed ? "none" : "auto"};

            &:before {
              content: " ";
              display: inline-block;
              width: 8px;
              height: 8px;
              background: red;
              border-radius: 8px;
              margin: 0 8px;
            }

            & > p {
              opacity: ${this.state.zoomed ? 0 : 1};
              transition: 0.12s ease-in-out opacity;
            }
          `}
        >
          <Text color="white">{name}</Text>
        </div>
      </Marker>
    )
  }

  _goToCoords = station => {
    const viewport = {
      ...this.state.viewport,
      longitude: station.coordinates[0],
      latitude: station.coordinates[1],
      zoom: 5,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    }
    this.setState({ viewport })
    this.setState({ zoomed: station })
  }

  _zoomOut = () => {
    const viewport = {
      ...this.state.viewport,
      longitude: this.defaultViewport.longitude,
      latitude: this.defaultViewport.latitude,
      zoom: this.defaultViewport.zoom,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    }
    this.setState({ viewport })
    this.setState({ zoomed: "" })
  }

  render() {
    const { viewport, settings, interactionState, zoomed } = this.state

    return (
      <MapGL
        {...viewport}
        {...settings}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={this._onViewportChange}
        onInteractionStateChange={this._onInteractionStateChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        css={css`
          position: relative;
        `}
      >
        {bartStations.map(this._renderMarker)}
        {zoomed && (
          <Flex
            p="3"
            bg="grey"
            color="black"
            flexDirection="column"
            alignItems="flex-end"
            css={css`
              position: absolute;
              top: 12px;
              right: 12px;
            `}
          >
            <Box mb="2">
              <button onClick={() => navigate(zoomed.to)}>
                Go to {zoomed.name} initiative page
              </button>
            </Box>
            <Box>
              <button onClick={() => this._zoomOut()}>Zoom back out</button>
            </Box>
          </Flex>
        )}
      </MapGL>
    )
  }
}
